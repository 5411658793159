@import '../../scss/base.scss';

.explore {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include mobile {
      justify-content: space-between;
    }

    > h2 {
      margin: 0;
      @include title;

      @include mobile {
        font-size: 20px;
        margin-top: 0;
      }
    }

    > a {
      box-sizing: border-box;
      position: absolute;
      z-index: 5;
      right: calc(50% - (1074px / 2) + 20px);
      // height: 32px;
      // min-width: 78px;
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 18px;
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      color: #00ad66;
      border-bottom: 1px solid #00ad66;

      @media (max-width: 1134px) {
        right: calc(30px + (35px * 2) + 20px + 20px);
      }

      @include mobile {
        position: static;
        height: 23px;
        min-width: 68px;
        font-size: 16px;
      }
    }

    > a:hover {
      color: #008c54;
      border-bottom: 1px solid #008c54;
    }
  }

  .breeds {
    .breed {
      a {
        text-decoration: none;
        overflow: hidden;
        display: block;

        span img {
          box-sizing: border-box;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          width: 165px;
          height: 165px;
        }

        p {
          margin: 0;
          margin-top: 10px;
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-weight: bold;
          text-align: center;
          color: #393939;
        }
      }
    }

    .withDogs {
      span img {
        width: 165px;
        height: 165px;
        aspect-ratio: attr(width) / attr(height);
        border: 5px solid #a6e8cd !important;
      }

      &:hover {
        span img {
          border: 5px solid #00ad66 !important;
        }

        a p {
          color: #00ad66;
        }
      }
    }

    .noDogs {
      span img {
        width: 165px;
        height: 165px;
        aspect-ratio: attr(width) / attr(height);
        border: 5px solid #a6e8cd !important;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }

    &.searchDesktop {
      grid-template-columns: repeat(auto-fill, minmax(117px, 1fr)) !important;
    }

    &.searchMobile {
      @include mobile {
        height: 325px !important;
        position: relative;
        >div{
          >div{
            >div{
              margin-right: 10px !important;
            }
          }
        }
        .pair {
          height: calc(150px * 2 + 5px) !important;
          gap:15px;
          .breed {
            width: 110px !important;
            margin-right: 10px;
          }
        }
      }
      .swiperButton {
        position: absolute;
        cursor: pointer;
        z-index: 9;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-size: 9px;
        background-color: rgba(255, 255, 255, 0.85);
        border: 0.5px solid rgba(57, 57, 57, 0.3);
        box-shadow: -4px 4px 10px rgba(57, 57, 57, 0.2);
        transition: all 300ms ease-out;
      
      }
      .swiperPrev {
        top: -57px;
        left: 190px;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        background-image: url('/prev-black.svg') !important;
    
      }
      .swiperButton:hover {
        background-color: #ffffff;
      }
      .swiperNext {
        top: -57px;
        right: 150px;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        background-image: url('/next-black.svg') !important;
        
      }

      .disabledPrev,
      .disabledNext {
        box-sizing: border-box;
        top: -57px;
        height: 30px;
        width: 30px;
        background-color: #ffffff;
        border: 2px solid #393939;
        border-radius: 50%;

        background-size: 9px;
        background-position: center;
        background-repeat: no-repeat;

        outline: none;
        cursor: auto;
        pointer-events: none;
        opacity: .35;
      }

      .disabledPrev {
        left: 190px;
        background-image: url('/prev-black.svg');
      }

      .disabledNext {
        background-image: url('/next-black.svg');
      }
    }

    &.desktop {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      width: 100%;
      margin-bottom: 30px;
      gap: 20px;
      justify-content: space-between;

      @include mobile {
        display: none;
      }

      .breed {
        div {
          width: 165px;
          height: 165px;
        }

        p {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    &.mobile {
      display: none;

      @include mobile {
        display: flex;
        width: calc(100% + 60px);
        margin-left: 0px;
        height: 370px;

        // Swiper
        > div:first-child {
          padding-left: 15px;
        }

        .pair {
          width: fit-content;
          height: calc(166px * 2 + 25px);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .breed {
            width: 150px;

            div {
              width: 100%;
              height: 120px;
            }

            p {
              height: 36px;
              font-size: 18px;
              line-height: 18px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              @include mobile {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .titleWrapper {
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
      margin-bottom: 20px;
    }

    h2 {
      @include title;
      margin: 0;

      @include tablet {
        margin-bottom: 10px;
      }
    }

    input,
    select,
    textarea {
      border: 1px solid #393939;
      border-radius: 60px;
      height: 40px;
      width: 30%;
      padding: 0 20px;
      background: url('/landing/search_icon.svg');
      background-repeat: no-repeat;
      background-position-y: 7px;
      background-position-x: 95%;

      @include tablet {
        width: unset;
      }
    }

    input,
    input::placeholder {
      color: #393939;
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 23px;

      @include tablet {
        font-size: 14px;
        line-height: 18px;
      }
    }

    input:focus {
      background: unset;
      outline: none;
    }

    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1.5em;
      width: 1.5em;
      border-radius: 50em;
      background: url('/landing/input_close.svg') no-repeat 30% 50%;
      background-size: contain;
      opacity: 0;
      pointer-events: none;
      cursor: pointer;

      @include mobile {
        height: 2em;
        width: 2em;
      }
    }

    input[type='search']:focus::-webkit-search-cancel-button {
      opacity: 1;
      pointer-events: all;
    }
  }

  .noResult {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 20px;
    /* BG/Aqua green */
    background: #c6f6e3;
    mix-blend-mode: normal;
    border-radius: 8px;

    @include mobile {
      padding: 5px;
    }

    p {
      margin: 0;
      width: 100%;
      text-align: center;
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 23px;
      color: #393939;

      @include mobile {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;

    button {
      width: 250px;
      margin: 0 10px;

      @include mobile {
        display: none;
      }

      div {
        font-size: 18px;
      }

      > img {
        width: 15px;
        margin-left: 10px;
      }
    }
  }
}
